:root {
  --color-grey: #F4F6FB;
  --color-blue: #1C5E7A;
  --color-red: #B32226;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none;
}

.App {
  display: flex;
  flex-direction: column;
}

.header, .footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: var(--color-grey); 
  padding: 10px;
  color: var(--color-blue); 
}

.header .inner, .footer .inner {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .inner img, .footer .inner img {
  height: 40px;
}

.header .inner button {
  color: var(--color-red);
  padding: 5px;
  background-color: transparent;
  border: 1px solid var(--color-blue);
  border-radius: 8px;
  cursor: pointer;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.body .inner {
  width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.body .inner .promoLarge {
  font-size: 40px;
  font-weight: bold;
  padding: 30px 20px; 
  text-align: center;
  color: var(--color-blue); 
}
.body .inner .promoMedium {
  font-size: 30px;
  font-style: italic;
  line-height: 1.8em;
  text-align: center;
  color: var(--color-blue); 
}
.body .inner .promoSmall {
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
}

.body .inner .overview {
  margin: 50px 0px;
  padding: 30px;
  display: flex;
  background-color: var(--color-grey);
  border-radius: 20px;
}
.body .inner .overview .checkboxes h3 {
  padding: 0;
  margin-top: 0;
  margin-bottom: 40px;
}
.body .inner .overview .checkboxes .checkboxline {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.body .inner .overview .checkboxes .checkboxline img {
  width: 20px;
  margin-right: 10px;
}
.body .inner .overview .clock img {
  width: 300px;
  padding: 20px;
}

.body .inner .startButton {
  margin: 50px;
}
.body .inner .startButton button {
  color: var(--color-red);
  font-size: 1.5em;
  font-style: italic;
  width: 200px;
  padding: 5px;
  background-color: transparent;
  border: 2px solid var(--color-blue);
  border-radius: 8px;
  cursor: pointer;
}

.footer {
  height: 100px;
}
.footer .inner {
  align-items: flex-start;
}
.footer .inner > div {
  padding: 5px;
  display: flex;
  flex-direction: column;
  font-size: 0.75em;
}



@media (max-width: 1000px) {
  .body .inner {
    width: 360px;
    font-size: 0.8em;
  }
  .header .inner, .footer .inner {
    width: 360px;
    font-size: 0.8em;
  }
  .header .inner img, .footer .inner img {
    height: 20px;
  }
  .body .inner .overview .clock img {
    width: 100px;
  }
  .body .planner img {
    width: 360px;
  }
  .header {
    font-size: 0.7em;
  }
  .body .inner .promoLarge {
    font-size: 2em; 
  }
  .body .inner .promoMedium {
    font-size: 1.5em;
    line-height: 1.5em;
  }
  .body .inner .promoSmall {
    font-size: 1.5em;
    text-align: center;
  }
  .body .inner .priser {
    font-size: 0.7em;
    text-align: center;
  }
}
